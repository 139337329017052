import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const OvernightBabySittingPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>Overnight Baby Sitting</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.overnightBabySitting.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
        • Are you working till late sometimes
        • Do you need to go out with friends
        • Do you have to travel outside the Province for work purposes
        Lady On Wheels Baby Sitters are there to help you through this difficult time to look after your kids while you doing other more important things that needs your attention. 
        Just give us a call will be there to take care of it.
        </div>
      </div>
    </Layout>
  )
}
)

export default OvernightBabySittingPage

export const pageQuery = graphql`
  query {
    overnightBabySitting: file(relativePath: {eq: "OvernightBabySitting.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`